
import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Document from '../../../../store/entities/jobcontext/document'

@Component
export default class DocumentForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  @Prop({type: Boolean, default: false}) public toEdit: boolean;
  document: Document = new Document();
  companyTypes: any;
  pageOrientationTypes: any;

  data() {
    return {
      companyTypes: [],
      pageOrientationTypes: []
    }
  }

  async mounted() {
    this.companyTypes = await this.$store.dispatch('document/companyTypes');
    this.pageOrientationTypes = await this.$store.dispatch('document/getPageOrientationTypes');
  }

  created() {
  }

  save() {
    (this.$refs.documentForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await this.$store.dispatch({
          type: this.toEdit ? 'document/update' : 'document/create',
          data: this.document
        });
        (this.$refs.documentForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
      }
    })
  }

  cancel() {
    (this.$refs.documentForm as any).resetFields();
    this.$emit('input', false);
  }

  visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      if (this.toEdit) {
        this.document = Util.extend(true, {}, this.$store.state.document.editDocument);
      } else {
        this.document = new Document();
      }
    }
  }

  documentRule = {
    name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
  }
}
