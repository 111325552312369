
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import PageRequest from '@/store/entities/page-request'
    import DocumentForm from './document-form.vue'
    import SortingModel from "@/store/entities/sortingmodel";
    import AbpBase from "@/lib/abpbase";
    import DocumentDto from "@/store/entities/jobcontext/document";
    import DocumentConfigurationForm from "@/views/setting/jobcontext/document/documentconfiguration-form.vue";


    @Component({
        components: {DocumentForm, DocumentConfigurationForm}
    })
    export default class Document extends AbpBase {
        edit() {
            this.editModalShow = true;
        }

        configure() {
            this.configurationModalShow = true;
        }

        pagerequest: PageRequest = new PageRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;
        configurationModalShow: boolean = false;

        get list() {
            return this.$store.state.document.list;
        };

        async search() {
            this.$store.commit('document/setCurrentPage', 1);
            await this.getPage();
        }

        get loading() {
            return this.$store.state.document.loading;
        }

        create() {
            this.$store.commit('document/edit', new DocumentDto());
            this.createModalShow = true;
        }

        pageChange(page: number) {
            this.$store.commit('document/setCurrentPage', page);
            this.getPage();
        }

        pageSizeChange(pageSize: number) {
            this.$store.commit('document/setPageSize', pageSize);
            this.getPage();
        }

        async getPage() {
            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'document/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.document.pageSize;
        }

        get totalCount() {
            return this.$store.state.document.totalCount;
        }

        get currentPage() {
            return this.$store.state.document.currentPage;
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getPage()
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Available for clients'),
            key: 'availableForClients',
            width: 200,
            sortable: 'custom'
        }, {
            title: this.L('System generated'),
            key: 'isAutoGenerated',
            width: 200,
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 230,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('document/edit', await this.$store.dispatch({
                                    type: 'document/get',
                                    data: params.row.id
                                }));
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small',
                            disabled: this.checkConfigureButtonAccess(params)
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('document/edit', await this.$store.dispatch({
                                    type: 'document/get',
                                    data: params.row.id
                                }));
                                this.configure();
                            }
                        }
                    }, this.L('Configure')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Document Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'document/delete',
                                            data: params.row
                                        })
                                        await this.getPage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getPage();
        }

        checkConfigureButtonAccess(params){
            return params.row.isAutoGenerated !== "Yes";
        }
    }
