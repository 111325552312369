import Entity from "@/store/entities/entity";

export default class DocumentDynamicProperty extends Entity<number> {
    documentId: number;
    type: number;
    values: string;
    name: string;
    isRequired: boolean;
    description: string;
}
